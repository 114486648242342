import { gql } from '@biletiniz/apollo';

export const VISA_PRICES_QUERY = gql`
  query VisaPrices($visaProviderType: String!) {
    visaPrices(visaProviderType: $visaProviderType) {
      currency
      prices {
        visaType
        pricesByAge {
          passengerType
          value
        }
      }
    }
  }
`;
